import styled from 'styled-components';
import media from '../MediaQueries';
import Img from 'gatsby-image';

export const ContactWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 60px;
  ${media.tablet`
  padding: 40px;
  flex-wrap: wrap;
  `}
  ${media.mobile`
  padding: 20px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  ${media.tablet`
  order: 0;
  margin-bottom: 1.5em;
  `}
`;

export const MapWrapper = styled.div`
  width: 100%;
  ${media.tablet`
  order: 1;
  `}
`;

export const GMap = styled(Img)``;

export const BoxWrapper = styled.div`
  display: flex;
  width: 80%;
  margin-top: 20px;
`;

export const Box = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  border: 3px solid black;
  margin: 0 auto;
`;

export const BB = styled.button`
  display: flex;
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  justify-content: center;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  a {
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
`;

export const BW = styled.button`
  display: flex;
  width: 100%;
  background: white;
  text-align: center;
  justify-content: center;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  a {
    color: black;
    text-decoration: none;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
`;
