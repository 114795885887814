import styled from 'styled-components';
import Img from 'gatsby-image';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative:
  z-index: 1;
  overflow: hidden;
  height: 450px;
  padding: 60px;
  ${media.tablet`
    height: 300px;
    padding: 40px;
  `}
  ${media.mobile`
    padding: 20px;
  `}
`;

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 450px;
  ${media.tablet`
    height: 300px;
  `}
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  text-align: left;
`;

export const Title = styled.h1`
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10;
  margin: 0;
  font-size: 34px;
  ${media.tablet`
  font-size: 28px;
  `}
`;
