import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Image, Title, TitleWrapper } from './HeroTitle.css';

const HeroTitle = ({ image, title }) => {
  return (
    <Wrapper>
      <Image fluid={image} />
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
    </Wrapper>
  );
};

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default HeroTitle;
