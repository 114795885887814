import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../containers/Layout/Layout';
import HeroTitle from '../components/HeroTitle/HeroTitle';
import SEO from '../components/SEO';
import {
  ContactWrapper,
  ContentWrapper,
  MapWrapper,
  GMap,
  BoxWrapper,
  Box,
  BB,
  BW,
} from '../styles/pages/contact.css';
import consts from '../util/consts';

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO meta={data.datoCmsContactPage.seoMetaTags} />
      <HeroTitle
        title={data.datoCmsContactPage.title}
        image={data.datoCmsContactPage.featuredImage.fluid}
      />
      <ContactWrapper>
        <MapWrapper>
          <a rel="noopener noreferrer" target="blank" href={consts.googleUrl}>
            <GMap fluid={data.datoCmsContactPage.mapImage.fluid} />
          </a>
        </MapWrapper>
        <ContentWrapper>
          <h2>JB Marble Co.</h2>
          <p>14656 Keswick St, Van Nuys, CA 91405</p>
          <BoxWrapper>
            <Box>
              <BB>
                <a href={`tel:${consts.phone}`}>Call</a>
              </BB>
              <BW>
                <a href={`mailto:${consts.email}`}>Email</a>
              </BW>
            </Box>
          </BoxWrapper>
        </ContentWrapper>
      </ContactWrapper>
    </Layout>
  );
};

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export const contactQuery = graphql`
  {
    datoCmsContactPage {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mapImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

export default Contact;
